<template>
  <Row>
    <Column :width="15">
      <transition name="rc-modal-fade">
        <div class="rc-modal-backdrop">
          <div class="rc-modal" style="width: 400px;">
          
            <Box style="background: green;">
              <TitleRow :left="4" :right="4"> Saving </TitleRow>
              <ErrorRow :error="error" />
              <SpacerRow />
              
              <Row>
                <Column :width="3" />
                <Column :width="9">
                  <Spacer />
                  <Value align="centre" fontSize="large" fontColour="light"> Please wait </Value>
                  <Spacer />
                </Column>
                <Column :width="3" />
              </Row>
              
              <Row >
                <Column :width="3" />
                <Column :width="4">
                </Column>
                <Column :width="1" />
                <Column :width="4" >
                </Column>
                <Column :width="3" />
              </Row>
              
              <SpacerRow />
              
            </Box>
          </div>
        </div>
          
      </transition>
    </Column>
  </Row>
</template>

<script>

import { mapGetters, mapActions, mapMutations } from 'vuex';
import MC from "@/domain/session/MC.js";

import ConstUtils   from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils  from '@/utils/StringUtils.js';

import Box    from "@/portals/shared/library/box/Box.vue";
import Row    from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import Value  from "@/portals/shared/library/value/Value.vue";
import Spacer from "@/portals/shared/library/spacer/Spacer.vue";

import SpacerRow from "@/portals/shared/library/spacer/SpacerRow.vue";
import TitleRow  from "@/portals/shared/library/title/TitleRow.vue";
import ErrorRow  from '@/components/row/ErrorRow.vue';

export default {
  name: "saving-dialog",
  components: {
    Box, Row, Column,
    TitleRow, Spacer, SpacerRow,
    Value,
    ErrorRow, 
  },
  props: {
    isAdmin: { type: Boolean, default: false },
    inspectionId: { type: String, default: "" },
  },
  data() {
    return {
      error: null,
      missing: false,
      
      isLoading: true,
      loadingMessage: null,
      MC: new MC(),
      Consts: ConstUtils,
      
      Strings: StringUtils,
      Contents: ContentUtils,
      
      kvps: {},
      isSending: false,
    };
  },
  computed: {
    ...mapGetters([
      'auth_connected',
      'auth_socket_status',
      'signin_event',
      'auth_client',
      'domain',
    ]),
    itemId: function() {
      return this.$route.params['id'];
    },
  },
  watch: {
    
  },
  mounted: function () {
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),
    ...mapMutations([
                   'authClear',
                   ]),

    start: function() {
      this.isLoading = false;
      this.loadingMessage = this.MC.Status.Loading.value();
    },
    
    callback: function(msg) {
      this.loadingMessage = msg;
    },
    back: function(good) {
      this.$emit("close", good);
    },
  },
}
</script>