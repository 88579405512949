<template>
  <Row v-if="ready">
    <div v-if="left" :class="leftPadding"> </div>
    <div :class="middle" ref="text">
      <Box>
        <Row v-if="showLabel">
          <Column :width="15">
            <div v-if="isTitleShowing" class="rc-font-small rc-text-label">
              {{ placeholderText() }}
            </div>
            <div v-else class="rc-font-small">
              <div v-if="placeholder != null" > 
                <div class="rc-font-small rc-text-label"> <br> </div>
              </div>
            </div>
          </Column>
        </Row>
        <Row>
          <Column :width="15">
            <Box>
              <Row>
                <Column :width="15">
                  <textarea 
                    :rows="rows" 
                    class="rc-input-field rc-input-width rc-text-value"
                    :placeholder="placeholderText()" 
                    :disable="disabled"
                    v-model="value"
                    v-on:keyup="withValue" 
                    style="height: 30px; padding-left: 20px; padding-right: 20px;"
                    />
                </Column>
              </Row>
            </Box>
            <Box>
              <Row v-if="!shouldShowRequired && !Strings.isEmpty(hint)">
                <Column :width="15">
                  <div class="rc-font-small rc-text-value">{{ hint }} <br /></div>
                </Column>
              </Row>
              <Row v-if="shouldShowRequired">
                <Column :width="15">
                  <div v-if="shouldShowRequired" class="rc-font-small rc-background-required">A value for {{ C.get(placeholder) }} is required</div>
                </Column>
              </Row>
            </Box>
          </Column>
        </Row>
      </Box>
    </div>
    <div v-if="right" :class="rightPadding"> </div>
  </Row>
</template>

<script>
import ContentUtils from '@/utils/ContentUtils';
import Catalog from '@/domain/session/Catalog.js';
import StringUtils from '@/utils/StringUtils.js';

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

export default {
  name: 'key-textarea',
  components: {
    Box, Row, Column,
  },
  props: {
    title: String,
    rows: {type: Number, default: 1},
    defaultValue: String,
    placeholder: String,
    isEmail: {type: Boolean, default: false},
    hint: String,
    type: String,
    showLabel: {type: Boolean, default: true},
    disabled: {type: Boolean, default: false},
    left: {type: Number, default: 0},
    right: {type: Number, default: 0},
    mandatory: {type: Boolean, default: false},
    required: {type: Boolean, default: false},
    clearValue: {type: Boolean, default: false},
  },
  data() {
    return {
      value: null,
      isTitleShowing: false,
      C: new Catalog(),
      timer: null,
      ready: false,
      Strings: StringUtils,
    };
  },
  watch: {
    value() {
      this.validate();
    },
    clearValue() {
      if (this.clearValue) {
        this.value = null;
      }
    },
    required() {
      if (this.required) {
        this.$refs.text.scrollIntoView();
      }
    },
  },
  computed: {
    shouldShowRequired: function() {
      if (this.required) {
        if (this.mandatory) {
          if (StringUtils.isEmpty(this.value)) {
            return true;
          }
        }
      }
      return false;
    },
    initialValue: function() {
      if (this.isEmail) {
        return this.defaultValue;
      }
      return this.defaultValue ? 
          ContentUtils.unescape(this.defaultValue) : 
          this.defaultValue;
    },
    leftPadding: function() {
      if (this.left > 0) {
        return "rc-box-col-" + this.left;
      }
      return "";
    },
    rightPadding: function() {
      if (this.right > 0) {
        return "rc-box-col-" + this.right;
      }
      return "";
    },
    middle: function() {
      var value = (15 - this.left - this.right);
      var css = "rc-box-col-" + value;
      return css;
    },
  },
  mounted: function() {
    this.value = this.initialValue;
    this.validate();
    this.ready = true;
  },
  methods: {
    placeholderText: function() {
      if (this.mandatory) {
        return this.C.get(this.placeholder) + "*";
      }
      return this.C.get(this.placeholder);
    },
    withValue: function() {
      this.emit();
    },
    emit: function() {
      clearInterval(this.timer)
      this.$emit('value', {'key': this.title, 'value': ContentUtils.encode(this.value)})
    },
    validate: function() {
      if (this.value != null && this.value.trim().length > 0) {
        this.isTitleShowing = true;
      } else {
        this.isTitleShowing = false;
      }
      if (this.mandatory) {
        this.$emit('requirementsMet', false);
      }
    },
    enter: function() {
      this.emit();
      this.$emit('enter', this.title);
    }
  },

}
</script>
